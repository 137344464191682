import React from "react";
import Link from "next/link";
import {motion} from "framer-motion";
import variants from "../../helpers/animation";

const MenuItem = ({name, path, toggle, icon}) => {
	return (
		<motion.li
			className="navigation__menu-item"
			variants={variants.menuItem}
			whileHover={{scale: 1.05}}
			whileTap={{scale: 0.95}}>
			<Link href={path} scroll={false}>
				<a className="navigation__menu-item-link" onClick={toggle}>
					<img src={icon.src} alttext={name}/>
					{name}
				</a>
			</Link>
		</motion.li>
	);
};

export default MenuItem;
