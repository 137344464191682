import React, {useRef} from "react";
import Link from "next/link";
import Image from "next/image";
import {motion, useCycle} from "framer-motion";
import {MenuToggle} from "../Navigation/MenuToggle";
import {Navigation} from "../Navigation/Navigation";
import variants from "../../helpers/animation";

import logo from "../../assets/images/msq-logo.svg";
import logoDark from "../../assets/images/msq-logo-dark.svg";

function Header({isLogoLight}) {
	const [isOpen, toggleOpen] = useCycle(false, true);
	const containerRef = useRef(null);
	const {height} = 1000;

	return (
		<div className="header__wrapper">
			<div className="header__title-wrapper">
				<Link href="/">
					<a className="header__logo-link">
						<img alt="MSQ" src={isLogoLight ? logoDark.src : logo.src} />
					</a>
				</Link>
			</div>
			<div className="header__menu-wrapper">
				<motion.nav
					className={`navigation ${isOpen ? "open" : "closed"} ${isLogoLight ? "dark" : "light"}`}
					initial={false}
					animate={isOpen ? "open" : "closed"}
					custom={height}
					ref={containerRef}>
					<motion.div
						className="navigation__background"
						variants={variants.menuSideBar}
					/>
					<Navigation toggle={() => toggleOpen()} />
					<MenuToggle toggle={() => toggleOpen()} />
				</motion.nav>
			</div>
		</div>
	);
}

export default Header;
