const variants = {
	menuItem: {
		open: {
			y: 0,
			opacity: 1,
			transition: {
				y: {stiffness: 1000, velocity: -100},
			},
			transitionEnd: { zIndex: 10 }
		},
		closed: {
			y: 50,
			opacity: 0,
			transition: {
				y: {stiffness: 1000},
			},
			transitionEnd: { zIndex: -1 }
		},
	},
	navigation: {
		open: {
			transition: {staggerChildren: 0.07, delayChildren: 0.2},
		},
		closed: {
			transition: {staggerChildren: 0.05, staggerDirection: -1 },
		},
	},
	menuSideBar: {
		open: (height = 1000) => ({
			clipPath: `circle(500px at 40px 40px)`,
			transition: {
				type: "spring",
				stiffness: 20,
				restDelta: 2,
			},
		}),
		closed: {
			clipPath: "circle(24px at 284px 39px)",
			transition: {
				delay: 0.5,
				type: "spring",
				stiffness: 400,
				damping: 40,
			},
		},
	},
	layout: {
		hidden: {opacity: 0, x: 0, y: 0},
		enter: {opacity: 1, x: 0, y: 0},
		exit: {opacity: 0, x: 0, y: -100},
	},
	shapes: {
		hidden: {opacity: 0},
		show: {opacity: 1},
		transition: {
			transitionDuration: 2
		}
	}
};

export default variants;
