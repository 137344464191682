import React from "react";
import MenuItem from "./MenuItem";
import {motion} from "framer-motion";
import variants from "../../helpers/animation";
import vidIcon from "../../assets/images/video.svg"
import eyeIcon from "../../assets/images/eye.svg"
import mailIcon from "../../assets/images/envelope.svg"

const itemIds = [
	{id: 1, name: "Watch Our Story", path: "#watchOurStory", icon: vidIcon},
	{id: 2, name: "Read Our Reports", path: "#readOurReports", icon: eyeIcon},
	{id: 3, name: "Get In Touch", path: "#getInTouch", icon: mailIcon},
];

export const Navigation = ({ toggle }) => (
	<motion.ul className="navigation__item-list" variants={variants.navigation}>
		{itemIds.map((i) => (
			<MenuItem name={i.name} path={i.path} key={i.id} toggle={toggle} icon={i.icon} />
		))}
	</motion.ul>
);
