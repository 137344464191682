import "flashgrid/dist/flashgrid.scss";
import "../styles/style.scss";
import Layout from "../components/Layout/layout";
import {AnimatePresence} from "framer-motion";
import Header from "../components/Header";
import {gsap} from "gsap/dist/gsap";
import Head from "next/head";

function MyApp({Component, pageProps, router}) {
	return (
		<>
			<Head>
				<meta name="viewport" content="viewport-fit=cover" />
			</Head>
			<AnimatePresence
				exitBeforeEnter
				initial={false}
				key={router.url}
				onExitComplete={() => window.scrollTo(0, 0)}>
				<Component {...pageProps} key={router.route} />
			</AnimatePresence>
		</>
	);
}

export default MyApp;
